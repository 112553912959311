import {
  Badge,
  Button,
  Pagination,
  Progress,
  Select,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import React, { useState } from "react";
import {
  formatDatetoISO,
  getStatusColor,
  getStatusText,
} from "../pages/utilities";
import AWS from "aws-sdk/global";
import { attachFiles, bulkUpdateObligationApi } from "../apis/userApis";
import { showNotification } from "@mantine/notifications";
import { IconTrash } from "@tabler/icons-react";

const BulkUploadProofs = ({ setReload, reload, obligations, setUploadModal }) => {
  console.log("obligations data: ", obligations);
  const [obligationData, setObligationData] = useState(obligations);
  const [files, setFiles] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  AWS.config.update({
    accessKeyId: `${process.env.REACT_APP_AWS_ACCESS_KAY_ID}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_SECRET_ACCESS_KEY}`,
    region: `${process.env.REACT_APP_REGION}`,
  });
  const s3 = new AWS.S3();
  const uploadFileToAws = async (e) => {
    console.log("yha aaya to h ");
    const file = e.target.files[0];
    console.log("file: ", file);
    const fileName = file.name;
    console.log(fileName);
    const type = file.type;
    console.log(type);

    const uploadParams = {
      Bucket: `${process.env.REACT_APP_AWS_BUCKET}`,
      Key: fileName,
      Body: file,
      ContentType: type,
      Metadata: {
        lastModified: file.lastModified.toString(),
        size: file.size.toString(),
      },
    };

    try {
      const data = await s3.upload(uploadParams).promise();
      console.log("data for file: ", data);
      console.log(`File uploaded successfully. ${data.Location}`);
      const location = data.Location;
      const name = data.key;
      const bucket = data.Bucket;

      let fileAttached; // Use let here

      for (let index = 0; index < obligationData.length; index++) {
        const fileforAttach = {
          createdBy: userData.name,
          createdByUserId: userData.id,
          location: location,
          name: name,
          parent: "obligation",
          parentId: parseInt(obligationData[index].id),
          type: type,
        };
        console.log("time for ", obligationData[index].id);
        fileAttached = await attachFiles({ body: fileforAttach });
        console.log(fileAttached.response);
        fileAttached = fileAttached.response;
      }
      setFiles([...files, fileAttached]);
      showNotification({
        title: "file uploaded",
        color: "green",
      });
    } catch (err) {
      console.error("Error uploading file:", err);
    }
  };

  const openFile = async ({ file }) => {
    console.log("file is here: ", file);
    const params = {
      Bucket: `${process.env.REACT_APP_AWS_BUCKET}`,
      Key: file.fileName,
      Expires: 600 * 60, // URL will expire in 10 hour
    };
    const previewUrl = await s3.getSignedUrl("getObject", params);
    window.open(previewUrl, "_blank");
  };
  const deleteFileFromAws = async ({ e, fileId, url }) => {
    e.preventDefault();
    console.log(url);
    console.log(fileId);
  };
  const handleComplianceChange = ({ value, rowId }) => {
    setObligationData((prevObligations) =>
      prevObligations.map((row) =>
        row.id === rowId ? { ...row, compliant: value } : row
      )
    );
  };
  const handleRemarkChange = ({ value, rowId }) => {
    setObligationData((prevObligations) =>
      prevObligations.map((row) =>
        row.id === rowId ? { ...row, remarks: value } : row
      )
    );
  };
  const updateObligations = async () => {
    const updatedObligationData = obligationData.map(obligation => ({
      id: obligation.id,
      remarks: obligation.remarks,
      compliant: obligation.compliant,
      donebyId: userData.id,
      donebyEmail: userData.email,
      doneby: userData.name,
    }));
    console.log("updated Data: ", updatedObligationData);
    const response = await bulkUpdateObligationApi({data: updatedObligationData});
    console.log("response: ", response);
    if (response.success) {
      setUploadModal(false);
      setReload(!reload);
    }
  };
  
  return (
    <div>
      <Table striped>
        <thead className="text-center">
          <tr>
            <th style={{ textAlign: "center" }}>obligation id</th>
            <th style={{ textAlign: "center" }}>obligation title</th>
            <th style={{ textAlign: "center" }}>second party</th>
            <th style={{ textAlign: "center" }}>due date</th>
            <th style={{ textAlign: "center" }}>status</th>
            <th style={{ textAlign: "center" }}>compliance</th>
            <th style={{ textAlign: "center" }}>remarks</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {obligationData?.length > 0 &&
            obligationData.map((row) => {
              return (
                <tr key={row.id}>
                  <td>{row.id}</td>
                  <td>{row.title}</td>
                  <td>{row.connectedParty}</td>
                  <td>{formatDatetoISO(row.duedate)}</td>
                  <td>
                    <Badge variant="light" color={getStatusColor(row.status)}>
                      {getStatusText(row.status)}
                    </Badge>
                  </td>
                  <td>
                    <Select
                      value={row.compliant}
                      onChange={(val) =>
                        handleComplianceChange({ value: val, rowId: row.id })
                      }
                      data={
                        new Date() > new Date(row.duedate)
                          ? [
                              {
                                label: "non-compliant",
                                value: "non-compliant",
                              },
                              {
                                label: "partially-compliant",
                                value: "partially-compliant",
                              },
                            ]
                          : [
                              {
                                label: "non-compliant",
                                value: "non-compliant",
                              },
                              {
                                label: "partially-compliant",
                                value: "partially-compliant",
                              },
                              { label: "compliant", value: "compliant" },
                            ]
                      }
                    />
                  </td>
                  <td>
                    <TextInput
                      value={row.remarks}
                      onChange={(e) =>
                        handleRemarkChange({
                          value: e.target.value,
                          rowId: row.id,
                        })
                      }
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <div className="mt-8">
        <div className="flex items-center justify-center w-full m-4">
          <label
            for="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-34 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                ariaHidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span>Click to upload</span> or drag and drop
              </p>
            </div>
            <input
              onChange={async (e) => {
                await uploadFileToAws(e);
              }}
              id="dropzone-file"
              type="file"
              className="hidden"
            />
          </label>
        </div>
        <div className="mt-4">
          {files?.length > 0 &&
            files.map((file) => {
              return (
                <div key={file.id} className="flex block h-10 mb-4 w-fulll">
                  <div className="center flex items-center justify-center w-1/12">
                    <svg
                      width="24"
                      height="35"
                      viewBox="0 0 24 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 9.29688V0H1.5C0.66875 0 0 0.731445 0 1.64062V33.3594C0 34.2686 0.66875 35 1.5 35H22.5C23.3312 35 24 34.2686 24 33.3594V10.9375H15.5C14.675 10.9375 14 10.1992 14 9.29688ZM18.0737 24.0632H14V29.5319C14 30.1362 13.5525 30.6257 13 30.6257H11C10.4475 30.6257 10 30.1362 10 29.5319V24.0632H5.92625C5.03375 24.0632 4.58813 22.8813 5.22188 22.1929L11.2481 15.6509C11.6637 15.199 12.335 15.199 12.7506 15.6509L18.7769 22.1929C19.4112 22.8813 18.9662 24.0632 18.0737 24.0632ZM23.5625 7.17773L17.4438 0.478516C17.1625 0.170898 16.7812 0 16.3813 0H16V8.75H24V8.33301C24 7.90234 23.8438 7.48535 23.5625 7.17773Z"
                        fill="#191D23"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col pl-3 w-full">
                    <div className="flex flex-row justify-between">
                      <div
                        style={{
                          lineHeight: "1.25rem",
                          letterSpacing: "-0.0313rem",
                          color: "#191D23",
                        }}
                      >
                        <Text className="font-normal">{file.fileName}</Text>
                      </div>
                    </div>
                    <div className="mt-3">
                      <Progress
                        color={file.errored ? "red" : "teal"}
                        size="sm"
                        // animate={
                        //   file.fetchingUploadUrl ||
                        //   file.attaching === loadingStates.LOADING
                        // }
                        // value={
                        //   file.fetchingUploadUrl || file.errored
                        //     ? 100
                        //     : file.uploadPercentage
                        // }
                        value={100}
                      />
                    </div>
                  </div>
                  <div className="center inline-block">
                    <Button
                      // onClick={downloadFile(index)}
                      className="mx-3 w-60"
                      color="#46BDE1"
                      // disabled={file.downloading === loadingStates.LOADING}
                      // classNames={`${styles.btn} mx-3`}
                      variant="outline"
                      style={{
                        color: "#46BDE1",
                        borderColor: "#46BDE1",
                      }}
                      onClick={() => openFile({ file: file })}
                    >
                      Open
                    </Button>
                  </div>
                  {(userData.loginAs === "Ob-DeptAdmin" ||
                    userData.loginAs === "Ob-DeptUser") && (
                    <div className="center w-1/12 px-auto py-auto inline-block">
                      <IconTrash
                        onClick={(e) =>
                          deleteFileFromAws({
                            e: e,
                            fileId: file.id,
                            url: file.hostedLink,
                          })
                        }
                        className="mx-auto"
                        size={30}
                        color="red"
                      />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <Button
          onClick={(e) => {
            updateObligations();
          }}
        >
          submit
        </Button>
      </div>
    </div>
  );
};

export default BulkUploadProofs;
