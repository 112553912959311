import React, { useState } from "react";
import LegacyAgreementForm1 from "./LegacyAgreementForm1";
import LegacyAgreementForm3 from "./LegacyAgreementForm3";
import LegacyAgreementForm2 from "./LegacyAgreementForm2";
import { Stepper } from "@mantine/core";
import { useDispatch, useSelector } from 'react-redux';

function CreateNewObligation() {
  const FORM_STEPS = {
    STEP_1: 0,
    STEP_2: 1,
    STEP_3: 2,
  };

  const [uiConfigs, setUiConfigs] = useState({
    currentStep: FORM_STEPS.STEP_1,
  });

  const [details, setDetails] = useState({
    agreementId : 0,
    local: false,
    secondParty: "",
    parentAgreementId: 0, 
    typeOfAgreement: "",
    endDate: "",
  })

  const [file, setFile]= useState(null);

  return (
    <>
      <div>
        <Stepper
          color="blue"
          active={uiConfigs.currentStep}
          onStepClick={(step) => {
            setUiConfigs({
              ...uiConfigs,
              currentStep: step,
            });
          }}
          breakpoint="sm"
          style={{ paddingRight: "10px" }}
        >
          <Stepper.Step allowStepSelect={true} label="agreement details">
            <LegacyAgreementForm1               
              uiConfigs={uiConfigs}
              setUiConfigs={setUiConfigs}
              setFile={setFile}
              setDetails={setDetails}
              file={file}
            />
          </Stepper.Step>

          <Stepper.Step allowStepSelect={true} label="create obligation">
            <LegacyAgreementForm2
              uiConfigs={uiConfigs}
              setUiConfigs={setUiConfigs}
              file={file}
              setFile={setFile}
              details={details}
              view={false}
            />
          </Stepper.Step>
          <Stepper.Step allowStepSelect={true} label="obligation list">
            <LegacyAgreementForm3 
              uiConfigs={uiConfigs}
              setUiConfigs={setUiConfigs}
              details={details}
            />
          </Stepper.Step>
        </Stepper>
      </div>
    </>
  );
}

export default CreateNewObligation;
