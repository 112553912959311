import React, { useEffect, useState } from "react";
import {
  fetchBuOptions,
  getLocalAgreements,
  getLocalAgreementsCSV,
  getNamesofAgreement,
  getTitlesOfAgreement,
} from "../apis/userApis";
import {
  ActionIcon,
  Button,
  Pagination,
  Select,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { csvDownload, formatDatetoISO } from "./utilities";
import { IconEye } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { INDIA_STATES_UNIONS } from "./constants";
import { useDispatch, useSelector } from 'react-redux';

const DocsLocker = () => {
  const navigate = useNavigate();
  const [configs, setConfigs] = useState({
    loading: true,
    agreements: null,
    count: 0,
  });
  const [page, setPage] = useState(1);

  const [buData, setBuData] = useState(null);
  useEffect(() => {
    const getBuData = async () => {
      const response = await fetchBuOptions();
      setBuData(response.buData);
    };
    getBuData();
  }, []);
  const [applyFilters, setApplyFilters] = useState(false);
  const [filters, setFilters] = useState({
    location: null,
    titleOfAgreement: null,
    businessUnit: null,
    typeOfAgreement: null,
    OR: [
      { secondParty: { path: "$.name", string_contains: "" } },
        {
          secondParty: {
            path: "$.indi_name",
            string_contains: "",
          },
        },
    ],
  });
  console.log("filters: ", filters);
  const [categoryOptions, setCategoryOptions] = useState([
    "NDA",
    "Service Agreement",
  ]);
  const [nameOptions, setNameOptions] = useState([
    "truecounsel NDA",
    "tc services",
  ]);

  useEffect(() => {
    const getTitles = async () => {
      const response = await getTitlesOfAgreement();
      if (response.success) {
        setNameOptions(response.titlesOfAgreement);
      }
    };
    const getNames = async () => {
      const response = await getNamesofAgreement();
      if (response.success) {
        setCategoryOptions(response.namesOfAgreement);
      }
    };
    getTitles();
    getNames();
  }, []);

  useEffect(() => {
    const getAgreements = async () => {
      let filtersForData = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== null)
      );
      
      console.log("filtersForData: ", filtersForData);
      
      // Check if "OR" exists and has the expected structure before accessing its values
      if (
        filtersForData.OR &&
        filtersForData.OR[0] &&
        filtersForData.OR[0].secondParty &&
        filtersForData.OR[0].secondParty.string_contains === ""
      ) {
        filtersForData = Object.fromEntries(
          Object.entries(filtersForData).filter(([key]) => key !== "OR")
        );
      }
      
      console.log("Updated filtersForData: ", filtersForData);
      console.log("filtersForData2: ", filtersForData);
      const response = await getLocalAgreements({
        page,
        filters: filtersForData,
      });
      if (response.success) {
        setConfigs({
          loading: false,
          agreements: response.agreements,
          count: response.count,
        });
        console.log(configs);
      } else {
        setConfigs({
          loading: false,
          agreements: null,
          count: 0,
        });
      }
    };
    getAgreements();
  }, [page, applyFilters]);
  const onViewClick = (id) => {
    navigate(`/viewagreement/${id}`);
  };
  const exportCSV = async()=>{
    let filtersForData = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => value !== null)
    );
    
    console.log("filtersForData: ", filtersForData);
    
    // Check if "OR" exists and has the expected structure before accessing its values
    if (
      filtersForData.OR &&
      filtersForData.OR[0] &&
      filtersForData.OR[0].secondParty &&
      filtersForData.OR[0].secondParty.string_contains === ""
    ) {
      filtersForData = Object.fromEntries(
        Object.entries(filtersForData).filter(([key]) => key !== "OR")
      );
    }
    const response = await getLocalAgreementsCSV({page: 1,filters: filtersForData, });
    console.log("export csv response: ",response);
    if(response.success){
      const columnHeaders = Object.keys(response?.agreements[0]);
      csvDownload({
        data: response.agreements,
        headers: columnHeaders,
        name: "agreement",
      });
    }
   
  }
  return (
    <>
      <Text className="flex justify-start font-semibold text-xl m-4">
        agreements locker
      </Text>
      <div className="grid grid-cols-6 gap-4 m-4">
        <Select
          label="agreement type"
          placeholder="agreement type"
          value={filters.typeOfAgreement}
          data={categoryOptions}
          onChange={(val) => setFilters({ ...filters, typeOfAgreement: val })}
        />
        <Select
          placeholder="agreement title"
          label="title"
          value={filters.titleOfAgreement}
          data={nameOptions}
          onChange={(val) => setFilters({ ...filters, titleOfAgreement: val })}
        />
        {buData !== null && (
          <Select
            label="business unit"
            placeholder="select business unit"
            data={buData}
            onChange={(val) => setFilters({ ...filters, businessUnit: val })}
            value={filters.businessUnit}
          />
        )}
        <TextInput
          label="second party"
          type="text"
          placeholder="type to search..."
          value={filters.OR[0].secondParty.string_contains}
          onChange={(e) => {
            // const value = e.target.value;

            setFilters((prevFilters) => ({
              ...prevFilters, // Preserve the existing filters
              OR: [
                { secondParty: { path: "$.name", string_contains: e.target.value } },
                {
                  secondParty: {
                    path: "$.indi_name",
                    string_contains: e.target.value,
                  },
                },
              ],
            }));
          }}
        />

        {/* <Select
          placeholder="location"
          label="location"
          value={filters.location}
          data={INDIA_STATES_UNIONS}
          onChange={(val) => setFilters({ ...filters, location: val })}
        /> */}
      </div>
      <div className="m-4">
        <Button
          onClick={() => setApplyFilters(!applyFilters)}
          className="mr-4"
          color="green"
        >
          search
        </Button>
        <Button
          onClick={() => {
            setFilters({
              location: null,
              titleOfAgreement: null,
              businessUnit: null,
              typeOfAgreement: null,
              OR: [
                { secondParty: { path: "$.name", string_contains: "" } },
                  {
                    secondParty: {
                      path: "$.indi_name",
                      string_contains: "",
                    },
                  },
              ],
            });
            setApplyFilters(!applyFilters);
          }}
          color="red"
        >
          clear
        </Button>
      </div>
      {!configs.loading && configs.agreements && (
        <>
          <div className="flex justify-end m-4">
            <Button style={{ backgroundColor: "#46bde1"}} onClick={()=>exportCSV()}>export</Button>
          </div>
          <Table striped>
            <thead className="text-center">
              <tr>
                {/* <th style={{ textAlign: "center" }}>select</th> */}
                <th style={{ textAlign: "center" }}>agreement id</th>
                <th style={{ textAlign: "center" }}>agreement type</th>
                <th style={{ textAlign: "center" }}>title</th>
                <th style={{ textAlign: "center" }}>second party</th>
                <th style={{ textAlign: "center" }}>business unit</th>
                <th style={{ textAlign: "center" }}>start date</th>
                <th style={{ textAlign: "center" }}>end date</th>
                <th style={{ textAlign: "center" }}>obligations</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {!configs.loading &&
                configs.agreements !== null &&
                configs.agreements.map((row) => {
                  return (
                    <tr key={row.agreementId}>
                      {/* <td>
                        <input
                          type="checkbox"
                          id={`select-${row.agreementId}`}
                          // onChange={(e) =>
                          //   handleCheckboxChange(row.id, e.target.checked)
                          // }
                        />
                      </td> */}
                      <td>{row.agreementId}</td>
                      <td>{row.typeOfAgreement}</td>
                      <td>{row.titleOfAgreement}</td>
                      <td>
                        {row.secondParty?.name
                          ? row.secondParty.name
                          : row.secondParty.indi_name}
                      </td>
                      <td>{row.businessUnit}</td>
                      <td>{formatDatetoISO(row.dateOfContract)}</td>
                      <td>{formatDatetoISO(row.endDate)}</td>

                      <td className="text-center">
                        <div className="flex flex-row center justify-center">
                          <ActionIcon
                            onClick={() => {
                              onViewClick(row.agreementId);
                            }}
                            color="white"
                          >
                            <IconEye size={24} />
                          </ActionIcon>
                          {/* )} */}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <div className="flex flex-row justify-center my-4">
            <Pagination
              page={page}
              onChange={(page) => {
                setPage(page);
              }}
              total={Math.ceil(configs.count / 10)}
            />
          </div>
        </>
      )}
    </>
  );
};

export default DocsLocker;
