import {
  ActionIcon,
  Badge,
  Button,
  Modal,
  Pagination,
  Table,
  Text,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import AddUserModal from "./AddUserModal";
import { useParams } from "react-router-dom";
import { getBuDetails } from "../apis/userApis";
import { IconEdit } from "@tabler/icons-react";
import {
  getActFrequencyText,
  getDepartmentText,
  mapRoleToLabel,
} from "./utilities";
import { useDispatch, useSelector } from "react-redux";

const ViewBu = () => {
  const { id } = useParams();
  const [dataToUpdate, setDataToUpdate] = useState(null);
  console.log(id);
  const [userModal, setUserModal] = useState(false);
  const [editUserId, setEditUserId] = useState(0);
  const [buData, setBuData] = useState(null);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [users, setUser] = useState(null);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const buDetails = async () => {
      const response = await getBuDetails({ id, page });
      console.log(response);
      if (response.success) {
        setCount(response.count);
        setUser(response.users);
        setBuData(response.buDetails);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    buDetails();
  }, [page, reload]);
  function reloadagain() {
    setReload(!reload);
  }
  return (
    <>
      <Modal
        size="calc(80vw)"
        opened={userModal}
        onClose={() => setUserModal(false)}
        title={editUserId ? "update user details" : "add user"}
      >
        <AddUserModal
          reload={reloadagain}
          dataToUpdate={dataToUpdate}
          buId={id}
          setUserModal={setUserModal}
        />
      </Modal>
      {!loading && buData !== null && (
        <div className="m-4 flex justify-between">
          <div>
            <Text>business unit</Text>
            <Text className="font-semibold">{buData.name}</Text>
          </div>
          <div>
            <Text>enabled</Text>
            <Badge color={buData.enabled ? "indigo" : "red"}>
              {buData.enabled ? "yes" : "no"}
            </Badge>
          </div>
        </div>
      )}
      <div className="m-4">
        <div className="flex justify-between">
          <Button
            style={{ backgroundColor: "rgb(117, 226, 255)" }}
            variant="filled"
            onClick={(e) => setUserModal(true)}
          >
            add user
          </Button>
          <div></div>
        </div>
      </div>
      <div className="m-4">
        {!loading && count > 0 && users !== null && (
          <>
            <Table striped>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>id</th>
                  <th style={{ textAlign: "center" }}>name</th>
                  <th style={{ textAlign: "center" }}>email</th>
                  <th style={{ textAlign: "center" }}>role</th>
                  <th style={{ textAlign: "center" }}>department</th>
                  <th style={{ textAlign: "center" }}>enabled</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {users.map((row) => (
                  <tr key={row.id}>
                    <td className="text-center"> {row.id} </td>
                    <td className="text-center"> {row.name} </td>
                    <td className="text-center"> {row.email} </td>
                    <td className="text-center">
                      {row.role
                        .split(",")
                        .map((role) => mapRoleToLabel(role))
                        .join(", ")}
                    </td>

                    <td className="text-center">
                      {row.role !== "Ob-Admin" &&
                      row.role !== "Ob-COO" &&
                      row.role !== "Ob-Auditor"
                        ? getDepartmentText(row.department)
                        : "--"}
                    </td>
                    <td className="text-center">
                      <Badge color={row.enabled ? "indigo" : "red"}>
                        {row.enabled ? "yes" : "no"}
                      </Badge>
                    </td>
                    <td className="text-center">
                      <ActionIcon
                        color="white"
                        onClick={(e) => {
                          setEditUserId(row.id);
                          setUserModal(true);
                          setDataToUpdate(row);
                        }}
                      >
                        <IconEdit size={24} />
                      </ActionIcon>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="flex flex-row justify-center my-4">
              <Pagination
                page={page}
                onChange={(page) => {
                  setPage(page);
                }}
                total={Math.ceil(count / 10)}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ViewBu;
