import { Avatar, Menu, Select, Text } from "@mantine/core";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { mapRoleToLabel } from "./utilities";
import { IconDoorExit, IconExchange } from "@tabler/icons-react";
const AppHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getCookiesSession = (cookieName) => Cookies.get(cookieName);
  const deleteCookie = (cookieName) => {
    Cookies.remove(cookieName, { domain: "truecounsel.in" });
  };
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [opened, setOpened] = useState(true);

  const logoutHandler = () => {
    localStorage.clear();
    window.location.reload();
    if (getCookiesSession("trueCounselUserData")) {
      deleteCookie("trueCounselUserData");
    }
    navigate('/login', { replace: true });
  };
  console.log("userData: ", userData);
  
  return (
    <div className="flex flex-row justify-between mx-8 my-2">
      <img
        style={{ width: "12rem" }}
        src="https://dev.truecounsel.in/_next/image?url=%2Fassets%2Fnewlogo.png&w=384&q=75"
        alt="TrueCounsel"
      />
      <div className="flex">
        {/* <Select
          required
          className="max-w-lg mr-2"
          placeholder="Select Role to login as"
          data={roles.map((role) => ({
            label: role.name,
            value: role.id,
            pass: role,
          }))}
          // label={currentUser.loginAs.name}
          value={currentUser.loginAs.id}
          onChange={(pass) => handleRoleChange(pass)}
        /> */}
        <Select
        className="flex items-center cursor-pointer mr-4"
        value={userData.loginAs}
        data={userData.role.split(",").map((role) => ({
          value: role,
          label: mapRoleToLabel(role),
        }))}
        // data={userData.role.split(",")}
        onChange={(val)=>{
          const user = {...userData, "loginAs" :val };
          localStorage.setItem("userData", JSON.stringify(user));
          window.location.reload();
        } }
        />
        <Menu>
          <Menu.Target>
            <div
              onClick={() => {
                setOpened(!opened);
              }}
              className="flex items-center cursor-pointer"
            >
              {userData.picture?.length ? (
                <Avatar size="md" src={userData.picture} radius="xl" />
              ) : (
                <Avatar size="md" radius="xl">
                  {userData.name[0].toUpperCase()}
                </Avatar>
              )}
              <div className="ml-2 flex flex-col">
                <Text size="md">{userData.name}</Text>
                <Text size="xs">{userData.email}</Text>
              </div>
            </div>
          </Menu.Target>
          <Menu.Dropdown>
          <Menu.Item onClick={()=>window.open(process.env.REACT_APP_LANDING_URL, "_blank")} icon={<IconExchange size={14} />}>
              choose manager
            </Menu.Item>
            <Menu.Item
              onClick={logoutHandler}
              icon={
                <IconDoorExit size={14}/>
              }
            >
              Logout
            </Menu.Item>
            <Menu.Item>

            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </div>
  );
};

export default AppHeader;
